<template>

    <CustomSelect
        :text="date.month.text"
        :value="date.month.value"
        :months="availableMonths"
        @update:value="changeMonth"
    />

    <div class="calendar__content">
        <div v-if="loading" class="calendar__loading">
            <svg width="100" height="100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/>
                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" class="spinner"/>
            </svg>
        </div>

        <div v-if="!loading" class="calendar-events">
            <div v-if="dates.length === 0" class="no-shows">
                <h2 class="has-h-3-font-size">Der er desværre ingen forestillinger i denne periode, prøv en anden.</h2>
            </div>
            <template v-for="event in dates" :key="event.date">
                <div class="event-group">
                    <div class="group-title">
                        <p class="has-h-3-font-size">{{ formatDate(event.date) }}</p>
                        <p>{{ formatWeekday(event.date) }}</p>
                    </div>
                    <ul>
                        <li v-for="show in event.shows" :key="show.eventId">
                            <div class="event-show">
                                <a :href="show.permalink" class="show-title has-h-4-font-size">{{ show.title }}</a>
                                <p class="show-time">
                                    <span v-if="show.stage">{{ show.stage.label }}</span>
                                    <span> kl. {{ formatTime(show.show_time.date) }}</span>
                                </p>

                                <p v-if="show.show_info" class="label show-info text--small">{{ show.show_info }}</p>
                            </div>
                            <div v-if="!show.noSale" class="show-buying">
                                <a v-if="show.availability === 1" href="#" class="button button--outline button--secondary button--big button--disabled">Udsolgt</a>
                                <a v-else href="#" :data-event_no="show.eventId" :data-show_no="show.showNo" class="basm basm_select button button--outline button--secondary button--big">Køb billet</a>

                                <div v-if="show.availability === 2" class="labels">
                                    <p class="label few-seats text--small">Få pladser</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import CustomSelect from "./CustomSelect.vue";

const date = ref({
    month: {
        text: new Date().toLocaleString('da-DK', {month: 'long'}),
        value: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    }
})

// Listen for the CustomSelect component's "update:value" event, and update the date.month value
const changeMonth = (value, text, year) => {
    date.value.month.value = value
    date.value.month.text = text
    date.value.month.year = year
    loadShows()
}

    const dates = ref([]);

    const formatTime = (date) => {
        let d = new Date(date);
        let options = {
            hour: 'numeric',
            minute: 'numeric'
        };
        return d.toLocaleTimeString('da-DK', options);
    }

    const formatDate = (date) => {
        let d = new Date(date);
        let options = {
            month: 'numeric',
            day: 'numeric'
        };
        return d.toLocaleDateString('da-DK', options).replace(/\./g, '/');
    }
    const formatWeekday = (date) => {
        let d = new Date(date);
        let options = {
            weekday: 'long'
        };
        return d.toLocaleDateString('da-DK', options);
    }

    let loading = ref(false);

    const loadShows = async () => {
        let url      = await assembleUrl();
        loading.value = true;

        // fetch from url
        return await fetch(url)
        .then(response => response.json())
        .then(data => {
            let events = data.data;
            let sortedEvents = {};

            // Only show events, if there are shows, and they have postStatus is "publish" and not null
            Object.values(events).forEach((event) => {
                event.shows = event.shows.filter((show) => {
                    return show.postStatus === "publish" && show.postStatus !== null;
                });
                if (event.shows.length === 0) {
                    delete events[event.date];
                }
            });

            // Sort the events by their key
            Object.keys(events).sort().forEach(function(key) {
                sortedEvents[key] = events[key];
            });

            // Sort the shows by date, from the show_time object
            Object.values(sortedEvents).forEach((event) => {
                event.shows.sort((a, b) => {
                    return new Date(a.show_time.date) - new Date(b.show_time.date);
                });
            });

            // Set the sorted events to the dates variable
            dates.value = sortedEvents;
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            loading.value = false;
        });
    }

    // Load all months where there are shows
    const loadMonths = async () => {
        let url = "/wp-json/calendar/v1/events";
        return await fetch(url)
        .then(response => response.json())
        .then(data => {
            let months = data.data;

            // Only include shows that are published in WP
            Object.keys(months).forEach((key) => {
                months[key].shows = months[key].shows.filter((show) => {
                    return show.postStatus === "publish";
                });
            });

            return months;
        })
        .then((months) => {
            // months are an array of dates, with a "date" property inside. We need to only get the unique months and year based on this
            let uniqueMonths = [];

            Object.keys(months).forEach((month) => {
                // Check if there are shows
                if (months[month].shows.length === 0) {
                    return;
                }
                let date = new Date(months[month].date);
                let monthNumber = date.getMonth() + 1;
                let monthText = date.toLocaleString('da-DK', {month: 'long'});
                let monthYear = date.getFullYear();
                let monthObject = {
                    month: monthNumber,
                    month_text: monthText,
                    month_year: monthYear
                }
                // Get the unique months and year
                if (!uniqueMonths.some(e => e.month === monthNumber && e.month_year === monthYear)) {
                    uniqueMonths.push(monthObject);
                }
            });

            // Sort the months by date
            uniqueMonths.sort((a, b) => {
                return new Date(a.month_year, a.month) - new Date(b.month_year, b.month);
            });

            return uniqueMonths;
        })
        .catch(error => {
            console.log(error);
        });
    }

    // Initialize the availableMonths variable
    const availableMonths = ref([]);

    // Generate select options from the sortedMonths object
    const generateOptions = async () => {
        let months = await loadMonths();
        let options = [];
        Object.values(months).forEach((month) => {
            options.push({
                value: month.month,
                text: month.month_text + " " + month.month_year,
                year: month.month_year
            });
        });
        availableMonths.value = options;
    }

    const assembleUrl = async () => {
        let base = "/wp-json/calendar/v1/events";
        let url;

        // If month has been selected, add it to the url
        if(date.value.month.value) {
            // url = base + "/month/"+date.value.month.value;
            url = base + "/year/"+date.value.month.year+"/"+date.value.month.value;

        }
        return url;
    }

    onMounted(() => {
        // Generate the select options
        generateOptions();

        // Load default shows
        loadShows();
    })

</script>

<style scoped>.calendar__content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.event-show {
    padding-right: 5%;
    word-break: break-word;
}
.spinner {
    transform-origin: center;
    animation: spinner .75s infinite linear;
    fill: white;
}
@keyframes spinner {
    100% {
        transform: rotate(360deg)
    }
}

</style>