<script setup>
import {onMounted, ref} from "vue";

const props = defineProps( {
    text: {
        type: String,
        default: '',
        required: false
    },
    value: {
        type: Number,
        default: '',
        required: false
    },
    months: {
        type: Array,
        default: [],
        required: false
    },
    tabindex: {
        type: Number,
        default: 0,
        required: false
    }
})

const emits = defineEmits(['update:value'])
const updateValue = (value, text, year) => {
    emits('update:value', value, text, year)
}

// Set the options of the select, based on the months prop, when mounted
// const options = ref([])
// onMounted(() => {
//     options.value = props.months
// })

const selected = ref(props.text)
const open = ref(false)


</script>

<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ selected }}
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <div
              v-for="(option, i) of months"
              :key="i"
              @click="
                selected = option.text;
                open = false;
                updateValue(option.value, option.text, option.year);
              ">
                {{ option.text }}
            </div>
        </div>
    </div>
</template>

<style scoped>
.custom-select {
    margin-bottom: 2rem;
    position: relative;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 47px;
    font-family: 'Domaine Display',Helvetica,serif;
}
@media all and (min-width: 1024px) {
    .custom-select {
        max-width: 25%;
    }
}

.custom-select .selected {
    background-color: transparent;
    border-bottom: 1px solid #666666;
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    text-transform: capitalize;
}

.custom-select .selected.open {
    border-bottom: 1px solid #ad8225;
}

.custom-select .selected:after {
    position: absolute;
    content: "";
    top: 22px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #fff transparent transparent transparent; /* Chevron */
}

.custom-select .items {
    color: #fff;
    overflow: hidden;
    border-bottom: 1px solid #ad8225;
    position: absolute;
    background-color: #463030;
    left: 0;
    right: 0;
    z-index: 1;
}


.custom-select .items div {
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    text-transform: capitalize;
}

.custom-select .items div:hover {
    background-color: rgba(255, 255, 255, .1);
}

.selectHide {
    display: none;
}
</style>
